import { SharedModule } from 'src/app/shared';
import { CargowiseRoutingModule } from './cargowise-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CargowiseComponent } from './cargowise.component';

@NgModule({
  imports: [CommonModule, CargowiseRoutingModule, SharedModule],
  declarations: [CargowiseComponent],
})
export class CargowiseModule {}
