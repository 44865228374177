import { CargowiseModule } from './cargowise/cargowise.module';
import { NgModule } from '@angular/core';

// Dont remove this and flatpickrFactory. Is is called internally in flatpicker
import flatpickr from 'flatpickr';
import { French } from 'flatpickr/dist/l10n/fr';
import { Spanish } from 'flatpickr/dist/l10n/es';
import { FooterModule, HeaderModule } from '.';

export function flatpickrFactory(lang: any) {
  if (lang == 'fr') {
    flatpickr.localize(French);
  } else if (lang == 'es') {
    flatpickr.localize(Spanish);
  }
  return flatpickr;
}

@NgModule({
  declarations: [],
  imports: [FooterModule, HeaderModule, CargowiseModule],
  exports: [HeaderModule, FooterModule], // Because header and footer are used in app component
})
export class FeaturesModule {}
