<app-top-nav></app-top-nav>
<div class="header">
  <mat-toolbar color="primary">
    <div class="header__logo">
      <a (click)="$event.stopPropagation()">
        <img src="../../../assets/images/blue-logo.png" alt="logo" />
        <span class="header__logo-text">{{
          'HEADER.LOGO_CUSTOMER_PORTAL_TEXT' | translate
        }}</span>
      </a>
    </div>
  </mat-toolbar>
</div>
