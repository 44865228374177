import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateRouterLinkPipe } from 'src/app/core';

@Component({
  selector: 'app-error-handler',
  templateUrl: './error-handler.component.html',
  styleUrls: ['./error-handler.component.scss'],
})
export class ErrorHandlerComponent {
  @Input() code;

  constructor(
    private router: Router,
    public translateRouterLink: TranslateRouterLinkPipe,
    private route: ActivatedRoute
  ) {
    this.code =
      router.getCurrentNavigation()?.extras?.state?.code ||
      this.route.snapshot?.data['code'];
    this.handleEncodedUrl();
    this.handleLocaleDuplicate();
  }

  handleEncodedUrl() {
    if (this.isEncoded(this.router.url))
      this.router.navigateByUrl(decodeURIComponent(this.router.url || ''));
  }

  handleLocaleDuplicate() {
    // What happend if there are more than 2 occurance?
    let curentLang =
      this.translateRouterLink.localizeService?.parser?.currentLang;
    let currentUrl = this.router.url;
    let isMatched =
      currentUrl?.indexOf('/' + curentLang + '/' + curentLang + '/') > -1;
    if (isMatched) {
      let currentUrlArray = currentUrl?.split('/');
      this.router.navigateByUrl(
        '/' + curentLang + '/' + currentUrlArray[currentUrlArray.length - 1]
      );
    }
  }

  isEncoded(str: any) {
    return typeof str == 'string' && decodeURIComponent(str) !== str;
  }

  goToHome() {
    this.router.navigate(this.translateRouterLink.transform('/home'));
  }
}
