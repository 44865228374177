import { LanguagePipe } from './../../../core/pipes/language.pipe';
import { Component, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HeaderService } from '../header.service';
import { interval, Subscription } from 'rxjs';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { EnvService } from 'src/app/core';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnDestroy {
  languages: any = [];
  currentLang = 'English';
  currentDate = new Date();
  timerSub?: Subscription;

  langPipe = new LanguagePipe();

  @ViewChild('basicTimer') basicTimer: any;

  constructor(
    public translateService: TranslateService,
    public router: Router,
    public headerService: HeaderService,
    public localizeService: LocalizeRouterService,
    public envService: EnvService
  ) {
    this.languages = this.langPipe.langList;
    this.currentLang = this.languages.filter(
      (lang: any) => lang.route == localizeService.parser?.currentLang
    )[0]?.label;
    this.timerSub = interval(
      1000 * (this.envService.pageRefreshAfterAmountInSec + 1)
    ).subscribe((val: any) => {
      this.basicTimer.reset();
      this.basicTimer.start();
    });
    setTimeout(() => {
      this.selectLanguage(this.headerService.currentLang);
    }, 0);
  }

  get isTimerRunning() {
    if (!this.headerService.showTimer) {
      this.timerSub?.unsubscribe();
    }
    return this.headerService.showTimer;
  }

  selectLanguage(selectedLangRoute: string) {
    const selectedLang = this.languages.filter(
      (lang: any) => lang.route == selectedLangRoute
    )[0];
    if (!selectedLang) return;
    this.currentLang = selectedLang?.label;
    this.headerService.onLangChangeHandler(selectedLang?.route);
  }

  ngOnDestroy(): void {
    this.timerSub?.unsubscribe();
  }
}
