import { HeaderService } from './../header/header.service';
import { MatDialog } from '@angular/material/dialog';
import { interval, Subscription } from 'rxjs';
import { isEmpty } from 'lodash';
import { CargowiseService } from './cargowise.service';
import { Component, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { xml2js } from 'xml-js';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvService, TranslateRouterLinkPipe } from 'src/app/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { isArray } from 'lodash';

@Component({
  selector: 'app-cargowise',
  templateUrl: './cargowise.component.html',
  styleUrls: ['./cargowise.component.scss'],
})
export class CargowiseComponent implements OnDestroy {
  shipmentDetails: any = {};
  isLoading = false;
  isLabelCacheUpdated = false;
  getBookingDetailsSub?: Subscription;
  timerSub?: Subscription;
  submitBookingFormSub?: Subscription;
  selectedOption: any;
  bookingStatus: any = {};
  @ViewChild('confirmationPopupTemplate')
  confirmationPopup!: TemplateRef<any>;
  confirmationPopupRef: any;

  id: any;
  path: any;
  contactIndex: any;

  currentSpinnerMessageLabel: any;

  bookingForm = new FormGroup({
    comment: new FormControl(),
  });

  get comment() {
    return this.bookingForm.get('comment')?.value?.trim();
  }

  get isContainerModeFCL() {
    return (
      this.shipmentDetails?.ContainerMode &&
      this.shipmentDetails?.ContainerMode?._?.indexOf('FCL') > -1
    );
  }

  constructor(
    private cargowiseService: CargowiseService,
    private dialog: MatDialog,
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private router: Router,
    public translateRouterLink: TranslateRouterLinkPipe,
    private translateService: TranslateService,
    private _location: Location,
    private envService: EnvService
  ) {
    this.initProcess();
    this.onTranslateServiceLangChangeHandler();
  }

  initProcess() {
    this.id = this.route?.snapshot?.queryParamMap?.get('ID');
    this.path = this.route?.snapshot?.queryParamMap?.get('Path');
    this.contactIndex =
      this.route?.snapshot?.queryParamMap?.get('Contact_Index');
    this.headerService.currentLang =
      this.route?.snapshot?.queryParamMap?.get('Lang') || 'en';

    if (!this.id || !this.path || !this.contactIndex) {
      this.onAPIResultHandler(100);
    } else {
      this.getBookingDetails();
      this.timerSub = interval(
        this.envService.pageRefreshAfterAmountInSec * 1000
      ).subscribe((val: any) => {
        this.getBookingDetails();
      });
    }
  }

  onTranslateServiceLangChangeHandler() {
    const url = window.location.href;
    let queryString = url
      ? url.split('?').pop()
      : window.location.search.slice(1);
    this.translateService.onLangChange.subscribe(() => {
      setTimeout(() => {
        this._location.go(
          this.translateRouterLink.transform('/booking?' + queryString)[0]
        );
      });
    });
  }

  getBookingDetails() {
    this.isLoading = true;
    this.currentSpinnerMessageLabel =
      'CARGOWISE.TXT_SPINNER_MESSAGE_GETTING_SHIPMENT_DETAILS';
    let details: any = {
      ID: this.id,
      Path: '/abp-pending-sent-xml/' + this.id,
      Contact_Index: Number(this.contactIndex),
    };
    this.getBookingDetailsSub = this.cargowiseService
      .getBookingDetails(details)
      .subscribe((response: any) => {
        this.isLoading = false;
        if (response instanceof HttpErrorResponse) {
          this.onAPIResultHandler(404);
        } else {
          this.shipmentDetails = this.formatShipmentDetails(response);
        }
      });
  }

  formatShipmentDetails(xmlString: any) {
    let jsonShipmentDetails: any = xml2js(xmlString, {
      sanitize: true,
      compact: true,
      textKey: '_',
    });
    let formattedObject: any = {};
    if (jsonShipmentDetails.hasOwnProperty('UniversalShipment')) {
      formattedObject = jsonShipmentDetails['UniversalShipment'];
      formattedObject.SailingOptions = this.getSailingOptions(
        jsonShipmentDetails['UniversalShipment']
      );
      formattedObject.AttachedDocumentCollection = this.getDocuments(
        formattedObject?.AttachedDocumentCollection
      );
    }
    var approvebuttonText = document.getElementById('btnApprove')?.innerText;
    if (
      approvebuttonText &&
      (approvebuttonText == 'Approve' ||
        approvebuttonText == 'Aprobar' ||
        approvebuttonText == 'Approuver')
    )
      this.isLabelCacheUpdated = true;
    return formattedObject;
  }

  get documents() {
    return (
      this.shipmentDetails?.AttachedDocumentCollection?.AttachedDocument ??
      undefined
    );
  }

  getDocuments(attachedDocumentCollection: any) {
    if (
      !isArray(attachedDocumentCollection?.AttachedDocument) &&
      attachedDocumentCollection?.AttachedDocument
    ) {
      return {
        AttachedDocument: [attachedDocumentCollection?.AttachedDocument],
      };
    }
    return attachedDocumentCollection || [];
  }

  onDownloadDoc(docId: any) {
    this.isLoading = true;
    this.currentSpinnerMessageLabel =
      'CARGOWISE.TXT_SPINNER_MESSAGE_DOWNLOADING_DOCUMENT';
    this.cargowiseService
      .getDocDetail({
        DocumentID: docId,
        Job: this.shipmentDetails?.TrafficTechRef?._,
      })
      .subscribe((response: any) => {
        this.isLoading = false;
        if (response instanceof HttpErrorResponse) {
          this.onAPIResultHandler(400);
        } else {
          this.onDownloadDocSuccessHandler(response);
        }
      });
  }

  onDownloadDocSuccessHandler(response: any) {
    let jsonDocDetail: any = xml2js(response, {
      sanitize: true,
      compact: true,
      textKey: '_',
    });
    const linkSource =
      'data:application/octet-stream;base64,' +
      jsonDocDetail?.UniversalResponse?.Data?.UniversalEvent?.Event
        ?.AttachedDocumentCollection?.AttachedDocument?.ImageData?._;

    const downloadLink = document.createElement('a');
    const fileName =
      jsonDocDetail?.UniversalResponse?.Data?.UniversalEvent?.Event
        ?.AttachedDocumentCollection?.AttachedDocument?.FileName?._;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  getSailingOptions(shipmentDetails: any) {
    let options: any[] = [];
    for (const k in shipmentDetails) {
      if (k.toString().indexOf('SailingScheduleOption') > -1) {
        if (!isEmpty(shipmentDetails[k]?.SailingSchedule)) {
          let option: any = shipmentDetails[k];
          option.OptionNumber = k
            .toString()
            .replace('SailingScheduleOption', '');
          options.push(option);
        }
      }
    }
    return options;
  }

  whatActionBtnClick: any;
  onActionButtonClick() {
    this.confirmationPopupRef = this.dialog.open(this.confirmationPopup, {
      width: '50vw',
    });
  }

  onSubmitForm() {
    let details: any = {
      ID: this.id,
      Path: '/abp-pending-sent-xml/' + this.id,
      Contact_Index: Number(this.contactIndex),
      Option:
        this.whatActionBtnClick == 'APPROVE'
          ? Number(this.selectedOption.OptionNumber)
          : null,
      Comment: this.comment ?? '',
    };

    this.isLoading = true;
    this.currentSpinnerMessageLabel =
      'CARGOWISE.TXT_SPINNER_MESSAGE_SUBMISSION';
    this.dialog.closeAll();
    this.headerService.showTimer = false;
    this.bookingForm.get('comment')?.disable();
    this.timerSub?.unsubscribe();

    this.submitBookingFormSub = this.cargowiseService
      .submitConfirmationForm(details)
      .subscribe((result: any) => {
        this.isLoading = false;
        if (result instanceof HttpErrorResponse) {
          this.onAPIResultHandler(400);
        } else {
          this.bookingStatus.statusCode = 200;
        }
      });
  }

  onAPIResultHandler(statusCode: number) {
    this.dialog.closeAll();
    this.isLoading = false;
    this.headerService.showTimer = false;
    this.bookingStatus.statusCode = statusCode;
    this.timerSub?.unsubscribe();
  }

  ngOnDestroy(): void {
    this.getBookingDetailsSub?.unsubscribe();
    this.timerSub?.unsubscribe();
    this.submitBookingFormSub?.unsubscribe();
  }
}
