<app-tt-spinner
  [isLoading]="isLoading"
  [message]="currentSpinnerMessageLabel | translate"></app-tt-spinner>

<ng-container *ngIf="shipmentDetails as details">
  <div class="custom-container submission-result">
    <div
      class="important"
      [attr.code]="bookingStatus?.statusCode"
      *ngIf="bookingStatus">
      <ng-container
        [ngTemplateOutlet]="shipmentStatusTemplate"
        [ngTemplateOutletContext]="{
          statusCode: bookingStatus?.statusCode
        }">
      </ng-container>
    </div>
  </div>
  <div
    class="custom-container"
    *ngIf="
      bookingStatus?.statusCode !== 100 && bookingStatus?.statusCode !== 404
    ">
    <mat-tab-group mat-stretch-tabs>
      <mat-tab
        [label]="'CARGOWISE.TITLE_SHIPMENT-DETAILS' | translate | uppercase">
        <div
          class="cargowise-booking-form"
          [ngClass]="{
            submitted:
              bookingStatus?.statusCode === 400 ||
              bookingStatus?.statusCode === 200
          }">
          <div style="margin: 10px 0">
            {{ 'CARGOWISE.TXT_BOOKING-WARNING' | translate }}
            <span class="important">{{
              shipmentDetails.BookingApprovalCutoff?._
            }}</span>
          </div>
          <mat-accordion class="custom-accordion">
            <ng-container
              [ngTemplateOutlet]="shippingDetailsTemplate"
              [ngTemplateOutletContext]="{
                details: details
              }">
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="optionTemplate"
              [ngTemplateOutletContext]="{
                options: details.SailingOptions,
                form: bookingForm,
                isContainerModeFCL: isContainerModeFCL,
                bookingStatus: bookingStatus
              }">
            </ng-container>
          </mat-accordion>
        </div>
      </mat-tab>
      <mat-tab [label]="'CARGOWISE.TITLE_DOCUMENTS' | translate | uppercase">
        <ng-container *ngIf="documents?.length < 1">
          <mat-label style="margin-top: 10px; display: block">{{
            'CARGOWISE.LBL_NO_DOCUMENT_CONTENT_LABEL' | translate
          }}</mat-label>
        </ng-container>
        <ng-container *ngIf="documents?.length > 0">
          <mat-label style="margin-top: 10px; display: block">{{
            'CARGOWISE.LBL_DOCUMENT_CONTENT_LABEL' | translate
          }}</mat-label>
          <mat-list>
            <mat-list-item *ngFor="let item of documents">
              <ng-container *ngIf="item?.DocumentID?._">
                <button
                  mat-button
                  link2
                  (click)="onDownloadDoc(item?.DocumentID?._)">
                  {{ item?.Type?.Description?._ }}
                </button>
                &nbsp;&nbsp; ({{ item?.FileName?._ }})
              </ng-container>
            </mat-list-item>
          </mat-list>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-container>

<ng-template #shipmentStatusTemplate let-statusCode="statusCode">
  <ng-container *ngIf="statusCode === 200">
    <div>
      {{ 'SHARED.ERROR-HANDLER.TXT_200-MESSAGE' | translate }}
    </div>
  </ng-container>
  <ng-container *ngIf="statusCode === 100">
    <div>
      {{ 'SHARED.ERROR-HANDLER.TXT_100-MESSAGE' | translate }}
    </div>
  </ng-container>
  <ng-container *ngIf="statusCode === 404">
    <div>
      {{ 'SHARED.ERROR-HANDLER.TXT_404-MESSAGE' | translate }}
    </div>
  </ng-container>
  <ng-container *ngIf="statusCode === 400">
    <div>
      {{ 'SHARED.ERROR-HANDLER.TXT_400-MESSAGE' | translate }}
    </div>
  </ng-container>
  <ng-container *ngIf="statusCode === 500">
    <div>
      {{ 'SHARED.ERROR-HANDLER.TXT_500-MESSAGE' | translate }}
    </div>
  </ng-container>
</ng-template>

<ng-template #shippingDetailsTemplate let-details="details">
  <mat-expansion-panel
    [expanded]="true"
    hideToggle="true"
    class="mat-elevation-z0 shipment-details">
    <mat-expansion-panel-header #panelDetails (click)="panelDetails._toggle()">
      <mat-panel-title>
        {{ 'CARGOWISE.TITLE_SHIPMENT-DETAILS' | translate | uppercase }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <div class="row">
        <div class="col-6">
          <div class="label-value">
            <div>{{ 'CARGOWISE.LBL_TT-REF' | translate }}:</div>
            <div>{{ details.TrafficTechRef?._ }}</div>
          </div>
          <div class="label-value">
            <div>{{ 'CARGOWISE.LBL_MODE' | translate }}:</div>
            <div>{{ details.Mode?._ }}</div>
          </div>
          <div class="label-value">
            <div>{{ 'CARGOWISE.LBL_INCOTERMS' | translate }}:</div>
            <div>{{ details.Incoterms?._ }}</div>
          </div>
        </div>
      </div>

      <div style="margin-top: 15px" class="row">
        <div class="col-12 col-md-6">
          <div class="label-value">
            <div>{{ 'CARGOWISE.LBL_SHIPPER' | translate }}:</div>
            <div>{{ details.Shipper?._ }}</div>
          </div>
          <div class="label-value">
            <div>{{ 'CARGOWISE.LBL_ORIGIN' | translate }}:</div>
            <div>{{ details.Origin?._ }}</div>
          </div>
          <div class="label-value" *ngIf="details.PortOfLoading?._">
            <div>{{ 'CARGOWISE.LBL_PORT-OF-LOADING' | translate }}:</div>
            <div>{{ details.PortOfLoading?._ }}</div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="label-value">
            <div>{{ 'CARGOWISE.LBL_CONSIGNEE' | translate }}:</div>
            <div>{{ details.Consignee?._ }}</div>
          </div>
          <div class="label-value">
            <div>{{ 'CARGOWISE.LBL_FINAL-DESTINATION' | translate }}:</div>
            <div>{{ details.FinalDestination?._ }}</div>
          </div>
          <div class="label-value" *ngIf="details.PortOfDischarge?._">
            <div>{{ 'CARGOWISE.LBL_PORT-OF-DISCHARGE' | translate }}:</div>
            <div>{{ details.PortOfDischarge?._ }}</div>
          </div>
        </div>
      </div>
      <div style="margin-top: 15px" class="row">
        <div class="col-12 col-md-6">
          <div class="label-value">
            <div>{{ 'CARGOWISE.LBL_PO' | translate }}:</div>
            <div>{{ details.PO?._ }}</div>
          </div>
          <div class="label-value">
            <div>{{ 'CARGOWISE.LBL_DESCRIPTION' | translate }}:</div>
            <div>{{ details.Description?._ }}</div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="label-value">
            <div>{{ 'CARGOWISE.LBL_TOTAL-PIECES' | translate }}:</div>
            <div>{{ details.TotalPieces?._ }}</div>
          </div>
          <div class="label-value">
            <div>{{ 'CARGOWISE.LBL_VOLUME' | translate }}:</div>
            <div>{{ details.Volume?._ }}</div>
          </div>
          <div class="label-value">
            <div>{{ 'CARGOWISE.LBL_WEIGHT' | translate }}:</div>
            <div>{{ details.Weight?._ }}</div>
          </div>
        </div>
      </div>
      <hr />
      <div style="margin-top: 15px" class="row">
        <div class="col-12 col-md-6">
          <div class="label-value">
            <div>
              {{ 'CARGOWISE.LBL_BOOKING-APPROVAL-CUT-OFF' | translate }}:
            </div>
            <div>{{ details.BookingApprovalCutoff?._ }}</div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="label-value">
            <div>{{ 'CARGOWISE.LBL_CARGO-READY-STATE' | translate }}:</div>
            <div>{{ details.CargoReadyDate?._ || 'N/A' }}</div>
          </div>
        </div>
        <div class="col-12 col-md-12" style="margin-top: 10px">
          <div class="label-value">
            <div>{{ 'CARGOWISE.LBL_BOOKING-NOTE' | translate }}:</div>
            <div [innerHTML]="details.BookingNotes?._ || 'N/A'"></div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</ng-template>

<ng-template
  #optionTemplate
  let-options="options"
  let-form="form"
  let-bookingStatus="bookingStatus"
  let-isContainerModeFCL="isContainerModeFCL">
  <mat-expansion-panel
    [expanded]="true"
    hideToggle="true"
    class="mat-elevation-z0 shipment-booking-options">
    <mat-expansion-panel-header #panelOptions (click)="panelOptions._toggle()">
      <mat-panel-title>
        {{ 'CARGOWISE.TITLE_BOOKING-OPTION' | translate | uppercase }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <label class="visually-hidden" id="radio-group-label"
        >Select your sailing option</label
      >
      <mat-radio-group
        [disabled]="
          bookingStatus?.statusCode === 400 || bookingStatus?.statusCode === 200
        "
        [(ngModel)]="selectedOption"
        [ngClass]="{ 'disabled-select': bookingStatus }"
        class="row">
        <ng-container *ngFor="let option of options; let i = index">
          <mat-radio-button class="col-12 col-md-6 col-lg-4" [value]="option">
            <div class="option-container">
              <div class="label-value" style="margin-bottom: 10px">
                <div>
                  {{ 'CARGOWISE.LBL_SALING-OPTION-TITLE' | translate }}
                  {{ option.optionNumber }}:
                </div>
                <div>{{ option.SailingSchedule?._ }}</div>
              </div>
              <div class="label-value" *ngIf="isContainerModeFCL">
                <div>{{ 'CARGOWISE.LBL_SI-CUT-OFF' | translate }}:</div>
                <div>{{ option.SICutOff?._ }}</div>
              </div>
              <div class="label-value" *ngIf="!isContainerModeFCL">
                <div>{{ 'CARGOWISE.LBL_CFS-CUT-OFF' | translate }}:</div>
                <div>{{ option.CFSCutOff?._ }}</div>
              </div>
              <div class="label-value">
                <div>{{ 'CARGOWISE.LBL_ETD-LOADING' | translate }}:</div>
                <div>{{ option.ETDLoading?._ }}</div>
              </div>
              <div class="label-value">
                <div>{{ 'CARGOWISE.LBL_ETA-DISCHARGE' | translate }}:</div>
                <div>{{ option.ETADischarge?._ }}</div>
              </div>
              <div class="label-value">
                <div>{{ 'CARGOWISE.LBL_ETA-DESTINATION' | translate }}:</div>
                <div>{{ option.ETADestination?._ }}</div>
              </div>
            </div>
          </mat-radio-button>
        </ng-container>
      </mat-radio-group>
      <mat-label class="important" style="margin-top: 10px; display: block">{{
        'CARGOWISE.LBL_ACTION-INSTRUCTION' | translate
      }}</mat-label>
      <form [formGroup]="form">
        <div class="additional-comment">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'CARGOWISE.LBL_COMMENT' | translate }}</mat-label>
            <textarea
              matInput
              formControlName="comment"
              rows="2"
              [required]="false"></textarea>
          </mat-form-field>
        </div>
      </form>

      <div
        *ngIf="
          bookingStatus?.statusCode !== 404 &&
          bookingStatus?.statusCode !== 200 &&
          bookingStatus?.statusCode !== 400
        "
        class="action-btn">
        <button
          class="tippy-tooltip-with-icon"
          *ngIf="!selectedOption || !comment || comment?.length === 0"
          [ngxTippy]="htmlActionBtnTemplate"
          [tippyProps]="{ allowHTML: true }"
          matPrefix
          mat-icon-button
          type="button"
          (click)="$event.stopPropagation()">
          <mat-icon>info</mat-icon>
          <div class="visually-hidden">
            <ng-container
              [ngTemplateOutlet]="htmlActionBtnTemplate"></ng-container>
          </div>
          <ng-template #htmlActionBtnTemplate>
            <div *ngIf="!isLabelCacheUpdated">
              Please clear browser cache for new release updates
            </div>
            <div>{{ 'CARGOWISE.LBL_REJECT-INSTRUCTION' | translate }}</div>
            <div>{{ 'CARGOWISE.LBL_APPROVE-INSTRUCTION' | translate }}</div>
          </ng-template>
        </button>

        &nbsp;
        <button
          mat-raised-button
          mat-button
          type="button"
          (click)="whatActionBtnClick = 'REJECT'; onActionButtonClick()"
          [disabled]="!comment || comment?.length === 0">
          {{ 'CARGOWISE.LBL_REJECT-BTN' | translate }}
        </button>
        &nbsp;&nbsp;
        <button
          [disabled]="!selectedOption"
          mat-button
          primary
          id="btnApprove"
          type="submit"
          (click)="whatActionBtnClick = 'APPROVE'; onActionButtonClick()">
          {{ 'CARGOWISE.LBL_APPROVE-BTN' | translate }}
        </button>
      </div>
    </div>
  </mat-expansion-panel>
</ng-template>

<ng-template #confirmationPopupTemplate>
  <div>
    <mat-card class="mat-elevation-z0">
      <mat-card-header>
        <mat-card-title style="margin-left: -20px"
          ><span
            class="material-symbols-outlined"
            style="position: absolute; top: 12px">
            warning </span
          ><span style="margin-left: 30px">
            {{ 'CARGOWISE.LBL_WARNING-TITLE' | translate }}
          </span></mat-card-title
        >
      </mat-card-header>
      <mat-card-content>
        {{
          (whatActionBtnClick === 'APPROVE'
            ? 'CARGOWISE.LBL_APPROVE-MESSAGE'
            : 'CARGOWISE.LBL_REJECT-MESSAGE'
          ) | translate
        }}
      </mat-card-content>
      <mat-card-actions align="end">
        <button
          mat-button
          mat-raised-button
          (click)="confirmationPopupRef.close()"
          type="button">
          {{ 'CARGOWISE.LBL_CANCEL-BTN' | translate }}
        </button>
        &nbsp;
        <button mat-button primary (click)="onSubmitForm()" type="button">
          {{ 'CARGOWISE.LBL_CONFIRM-BTN' | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</ng-template>
