import { EnvService } from 'src/app/core';
import { TtHttpClientService } from './../../core/services/tt-http-client.service';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CargowiseService {
  constructor(
    private http: TtHttpClientService,
    private envService: EnvService
  ) {}

  fakeSuccessSubmitOption = {
    message: 'Thank you. Your submission is completed successfully!',
  };

  fakeSuccessSubmitReject = {
    message: 'Thank you. Your reject submission is completed successfully!',
  };

  fakeDocumentCollection =
    '<AttachedDocumentCollection>' +
    '<AttachedDocument>' +
    '<FileName>RFIL-ANS-024-2023.xls</FileName>' +
    '<Type>' +
    '  <Code>CIV</Code>' +
    '  <Description>Commercial Invoice</Description>' +
    '</Type>' +
    '<DocumentID>cedddfd0-8ac9-4222-9fea-b4cbb5add1b0</DocumentID>' +
    '<IsPublished>true</IsPublished>' +
    '<SaveDateUTC>2023-02-23T10:22:00</SaveDateUTC>' +
    '<SavedBy>' +
    '  <Code>ASR</Code>' +
    '  <Name>Abida Sultana Rupa</Name>' +
    '</SavedBy>' +
    '<Source>' +
    '  <Code></Code>' +
    '  <Description></Description>' +
    '</Source>' +
    '<VisibleBranchCode></VisibleBranchCode>' +
    '<VisibleCompanyCode></VisibleCompanyCode>' +
    '<VisibleDepartmentCode></VisibleDepartmentCode>' +
    '</AttachedDocument>' +
    '<AttachedDocument>' +
    '  <FileName>for BL.docx</FileName>' +
    '  <Type>' +
    '    <Code>SLI</Code>' +
    '    <Description>Shippers Letter of Instruction</Description>' +
    '  </Type>' +
    '  <DocumentID>fa74ec49-714a-47be-b61e-abcf2d4fe592</DocumentID>' +
    '  <IsPublished>true</IsPublished>' +
    '  <SaveDateUTC>2023-02-23T10:23:00</SaveDateUTC>' +
    '  <SavedBy>' +
    '    <Code>ASR</Code>' +
    '    <Name>Abida Sultana Rupa</Name>' +
    '  </SavedBy>' +
    '  <Source>' +
    '    <Code></Code>' +
    '    <Description></Description>' +
    '  </Source>' +
    '  <VisibleBranchCode></VisibleBranchCode>' +
    '  <VisibleCompanyCode></VisibleCompanyCode>' +
    '  <VisibleDepartmentCode></VisibleDepartmentCode>' +
    '</AttachedDocument>' +
    '</AttachedDocumentCollection>';

  fakeBookingDetails =
    '<UniversalShipment>' +
    '<BookingApprovalCutoff>2023-02-03</BookingApprovalCutoff>' +
    '<TrafficTechRef>SHP00091816</TrafficTechRef>' +
    '<Shipper>Shanghai Aba Textile Co., Ltd.</Shipper>' +
    '<Consignee>Groupe Souris Mini</Consignee>' +
    '<PO>TESTONE</PO>' +
    '<Description>test1</Description>' +
    '<TotalPieces>0 PLT</TotalPieces>' +
    '<Volume>0.000 M3</Volume>' +
    '<Mode>SEA</Mode>' +
    '<Incoterms>FOB test1</Incoterms>' +
    '<Origin>Shanghai</Origin>' +
    '<FinalDestination>Quebec</FinalDestination>' +
    '<BookingNotes>23-Jan-23: rejected</BookingNotes>' +
    '<ContainerMode>LCL</ContainerMode>' +
    '<CargoReadyDate></CargoReadyDate>' +
    '<SailingScheduleOption1>' +
    '    <SailingSchedule>OOVCL CHICAGO 087E VIA VANCOUVER</SailingSchedule>' +
    '    <SICutOff>2023-02-05</SICutOff>' +
    '    <CFSCutOff>2023-02-05</CFSCutOff>' +
    '    <ETDLoading>2023-02-15</ETDLoading>' +
    '    <ETADischarge>2023-02-27</ETADischarge>' +
    '    <ETADestination>2023-03-01</ETADestination>' +
    '</SailingScheduleOption1>' +
    '<SailingScheduleOption2>' +
    '    <SailingSchedule>HAPAG NEWYORK 135E VIA PRINCE RUPPER</SailingSchedule>' +
    '    <SICutOff>2023-02-12</SICutOff>' +
    '    <CFSCutOff>2023-02-12</CFSCutOff>' +
    '    <ETDLoading>2023-02-22</ETDLoading>' +
    '    <ETADischarge>2023-03-05</ETADischarge>' +
    '    <ETADestination>2023-03-08</ETADestination>' +
    '</SailingScheduleOption2>' +
    '<SailingScheduleOption3>' +
    '    <SailingSchedule>HAPAG NEW YORK VIA VANCOUVER</SailingSchedule>' +
    '    <SICutOff>2023-02-12</SICutOff>' +
    '    <CFSCutOff>2023-02-12</CFSCutOff>' +
    '    <ETDLoading>2023-02-22</ETDLoading>' +
    '    <ETADischarge>2023-03-05</ETADischarge>' +
    '    <ETADestination>2023-03-08</ETADestination>' +
    '</SailingScheduleOption3>' +
    this.fakeDocumentCollection;

  getBookingDetails(details: any) {
    const url =
      '/api/ABP-HTTP-Request/triggers/manual/invoke?api-version=2022-05-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=' +
      this.envService.sig.clientRequest;
    if (this.envService.isTesting) {
      console.log(details);
      return of(this.fakeBookingDetails);
    }
    return this.http.postWithTextResponse(url, details).pipe(
      catchError(response => {
        return of(response);
      })
    );
  }

  getDocDetail(details: any) {
    const url =
      '/api/ABP-HTTP-Get-eDocs/triggers/manual/invoke?api-version=2022-05-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=' +
      this.envService.sig.docDetailRequest;

    return this.http.postWithTextResponse(url, details).pipe(
      catchError(response => {
        return of(response);
      })
    );
  }

  submitConfirmationForm(details: any) {
    const url =
      '/api/ABP-HTTP-ClientResponse/triggers/manual/invoke?api-version=2022-05-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=' +
      this.envService.sig.clientResponse;
    if (this.envService.isTesting) {
      console.log(details);
      return of(this.fakeSuccessSubmitOption);
    }
    return this.http.post(url, details).pipe(
      catchError(response => {
        return of(response);
      })
    );
  }
}
