<mat-sidenav-container cdkscrollable #sidenavContainer>
  <mat-sidenav-content>
    <!-- We have this div for scroll to top component -->
    <h1 class="visually-hidden" id="top-div">
      {{ 'HEADER.LOGO_CUSTOMER_PORTAL_TEXT' | translate }}
    </h1>
    <!-- Because WCAG, we need to have 2 app-header -->
    <app-header></app-header>
    <main
      [ngClass]="{
        home: !refresing && isCurrentRouteHome,
        'auto-height': isHeightAuto
      }">
      <router-outlet></router-outlet>
    </main>
    <app-tt-spinner
      [isLoading]="refresing"
      [fullBackground]="false"></app-tt-spinner>
    <app-footer></app-footer>
    <!-- scroll to top -->
    <div #scrollToRef class="scroll-to-top">
      <button
        mat-button
        mat-icon-button
        type="button"
        class="btn active"
        data-toggle="button"
        aria-pressed="true"
        (click)="onScrollToTop()">
        <span class="material-symbols-outlined"> arrow_upward </span>
      </button>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
