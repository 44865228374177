export const environment = {
  production: false,
  isTesting: false,
  webAPIRoot: 'https://automatedbookingprocess2.azurewebsites.net',
  sig: {
    clientRequest: 'jOq_BWSQhOXx0kx5OlY6FMKNApFLtHipMwOW1M7Ol80',
    clientResponse: 'spZJnu5uw6Vx3p3ZLJZPCaZ2Mr2U9-UIveFRFc9H1js',
    docDetailRequest: 'r-H5AnfnrfFRDBPlNk1TLGQel-t1sLisqugPFZDStfs',
  },
  refreshPageAfter: 300, // in seconds
};
