import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  get api() {
    const api = {
      getBookingDetails: 'someAPI',
      submitBookingOptions: 'anotherAPI',
    };
    return api;
  }

  get webAPIRoot() {
    return environment.webAPIRoot;
  }

  get flags() {
    return {
      enableExportFeatureForAgent: false,
    };
  }

  get isTesting() {
    return environment?.isTesting;
  }

  get sig() {
    return environment?.sig;
  }

  get pageRefreshAfterAmountInSec() {
    return environment?.refreshPageAfter;
  }

  get constants() {
    return {
      carriersRequiredNMFC: ['RDWY', 'NPME', 'HMES', 'RETL', 'EXLA'],
      volumeConstants: {
        maxLengthInches: 288,
        maxWidthHeightInches: 96,
        linearFootageFt: 10,
        totalWeightLbs: 5000,
        overDimensionLengthInches: 95,
        itemLengthInches: 120,
        estesGuaranteedServiceMaxLinearFt: 12,
      },
      navItems: {
        default: [],
        custom: [],
      },
      showWhatNew: true,
    };
  }
}
