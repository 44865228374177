import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlerComponent } from './shared/components/error-handler/error-handler.component';
import {
  AfterViewInit,
  Component,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { CanFindLocalizedRouteGuard } from './can-find-localized-route.guard';
import { Location } from '@angular/common';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { TranslateRouterLinkPipe } from './core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'TTInteractive';
  isCurrentRouteHome = true;
  isHeightAuto = false;
  navItems: any[] = [];
  @ViewChild(MatSidenav) sidenav: MatSidenav | undefined;
  @ViewChild(MatSidenavContent) sidenavContent: MatSidenavContent | undefined;
  @ViewChild('scrollToRef') scrollToRef: ElementRef | undefined;
  refresing = false;

  constructor(
    private router: Router,
    private location: Location,
    public translateSevice: TranslateService,
    private titleService: Title,
    private translateRouterLink: TranslateRouterLinkPipe
  ) {
    this.refresing = false;

    // NOTE: We must add the 404 route here to avoid this going through
    // localize-router in the app-routing.module.ts file.
    // Otherwise the catch-all would be under a locale too
    this.router.config?.push({
      path: '**',
      component: ErrorHandlerComponent,
      data: { code: 400, titleRef: 'SHARED.PAGE-TITLES.ERROR' },
      canActivate: [CanFindLocalizedRouteGuard],
    });
    this.onNavigatingToDefaultRoute();
  }

  onNavigatingToDefaultRoute() {
    const url = window.location.href;
    let queryString = url
      ? url.split('?').pop()
      : window.location.search.slice(1);
    this.router.navigate(
      this.translateRouterLink.transform('/booking?' + queryString)
    );
  }

  ngAfterViewInit(): void {
    // For scroll
    setTimeout(() => {
      this.sidenavContent?.elementScrolled().subscribe((event: any) => {
        let elClassList = this.scrollToRef?.nativeElement.classList;
        let currentTopOffset = this.sidenavContent?.measureScrollOffset('top');
        if (
          !elClassList.contains('show-scrollTop') &&
          currentTopOffset &&
          currentTopOffset > 50
        ) {
          elClassList.add('show-scrollTop');
        } else if (currentTopOffset == 0) {
          elClassList.remove('show-scrollTop');
        }
      });
    });
  }

  setDynamicPageTitle(event: RoutesRecognized) {
    let pageTitle = 'Booking confirmation';
    let childRoute = event.state.root.firstChild;
    let titleRef = childRoute?.data?.titleRef;
    while (!titleRef && childRoute) {
      childRoute = childRoute?.firstChild || null;
      titleRef = childRoute?.data?.titleRef;
    }
    if (titleRef) {
      pageTitle = this.translateSevice.instant(titleRef);
      pageTitle = pageTitle === titleRef ? 'Booking confirmation' : pageTitle;
    }
    this.titleService.setTitle(pageTitle);
  }

  onScrollToTop() {
    document.getElementById('top-div')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  @HostListener('document:keydown.tab', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    document.body.classList.add('is-tabbing');
  }
  @HostListener('document:click', ['$event']) onClickHandler(
    event: KeyboardEvent
  ) {
    document.body.classList.remove('is-tabbing');
  }
}
