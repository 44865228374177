import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'language',
})
export class LanguagePipe implements PipeTransform {
  langList: { code: number; route: string; label: string }[] = [
    {
      code: 1,
      route: 'en',
      label: 'English',
    },
    {
      code: 2,
      route: 'fr',
      label: 'Français',
    },
    {
      code: 3,
      route: 'es',
      label: 'Español',
    },
  ];

  transform(value: any, args?: any): any {
    if (!value) return 1;
    return (
      this.langList.filter((lang: any) => lang.route === value)[0]?.code || 1
    );
  }
}
