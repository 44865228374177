import { TTSpinnerModule } from './components/tt-spinner/tt-spinner.module';
import { NgModule } from '@angular/core';
import {
  CommonModule,
  DatePipe,
  CurrencyPipe,
  PercentPipe,
  DecimalPipe,
} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateRouterLinkPipe } from '../core';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

@NgModule({
  declarations: [TranslateRouterLinkPipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    NgxTippyModule,
    TTSpinnerModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    TranslateRouterLinkPipe,
    TranslateModule,
    NgxTippyModule,
    TTSpinnerModule,
  ],
  providers: [
    TranslateRouterLinkPipe,
    DatePipe,
    CurrencyPipe,
    PercentPipe,
    DecimalPipe,
  ],
})
export class SharedModule {}
