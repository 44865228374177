<div
  role="alert"
  *ngIf="isLoading"
  class="spinner3-wrapper"
  style="position: {{ position }};">
  <div>
    <div class="spinner3">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="text">
      {{ message || 'SHARED.TT-SPINNER.TXT_LOADING-MESSAGE' | translate }}
    </div>
  </div>
</div>
