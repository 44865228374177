import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { EnvService } from '..';

@Injectable({
  providedIn: 'root',
})
export class TtHttpClientService {
  baseUrl: string;
  headers: Headers | undefined;

  constructor(private httpClient: HttpClient, private envService: EnvService) {
    this.baseUrl = this.envService.webAPIRoot || '';
  }

  // This post is used to call API which params are string | number | null | undefined | customObject
  post<T>(url: string, params: any) {
    let request = this.httpClient.post<T>(this.baseUrl + url, params);
    if (!this.httpClient || !request) return EMPTY;
    return request.pipe(
      catchError((err: HttpErrorResponse) => {
        throw err;
      })
    );
  }

  // This POST take text reponse type
  postWithTextResponse(url: string, params: any) {
    let request = this.httpClient.post(this.baseUrl + url, params, {
      responseType: 'text',
    });
    if (!this.httpClient || !request) return EMPTY;
    return request.pipe(
      catchError((err: HttpErrorResponse) => {
        throw err;
      })
    );
  }

  // notice the <T>, making the method generic
  get<T>(url: string, params?: HttpParams): Observable<T> {
    let request = this.httpClient.get<T>(this.baseUrl + url, { params });
    if (!this.httpClient || !request) return EMPTY;
    return request.pipe(
      retry(2),
      catchError((err: HttpErrorResponse) => {
        throw err;
      })
    );
  }

  // This GET take any reponse type
  getWithTextResponse(url: string) {
    let request = this.httpClient.get(this.baseUrl + url, {
      responseType: 'text',
    });
    if (!this.httpClient || !request) return EMPTY;
    return request.pipe(
      retry(2),
      catchError((err: HttpErrorResponse) => {
        throw err;
      })
    );
  }

  patch<T>(url: string, params?: HttpParams): Observable<T> {
    let request = this.httpClient.patch<T>(this.baseUrl + url, { params });
    if (!this.httpClient || !request) return EMPTY;
    return request.pipe(
      retry(2),
      catchError((err: HttpErrorResponse) => {
        throw err;
      })
    );
  }

  onError(err: HttpErrorResponse) {
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${err.status}, body was: ${err.error}`
      );
    }

    // ...optionally return a default fallback value so app can continue (pick one)
    // which could be a default value
    // return Observable.of<any>({my: "default value..."});
    // or simply an empty observable
    return EMPTY;
  }
}
