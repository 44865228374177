import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Location } from '@angular/common';
import { TranslateRouterLinkPipe } from 'src/app/core/pipes/translate-router-link.pipe';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor(
    public router: Router,
    public localizeService: LocalizeRouterService,
    private _location: Location,
    public translateRouterLink: TranslateRouterLinkPipe
  ) {}

  showTimer = true;
  currentLang = 'en';

  isRouteActive(route: any) {
    let currentRoute = this.router.url.split(/(\#\?\;)+/)[0];
    if (route?.length > 0 && currentRoute.indexOf(route) > -1) {
      return true;
    }
    return false;
  }

  onLangChangeHandler(selectedLangRoute: any) {
    this.localizeService.parser.currentLang = selectedLangRoute; // selectedLangRoute must be en, fr, or es
    const url = window.location.href;
    let queryString = url
      ? url.split('?').pop()
      : window.location.search.slice(1);
    setTimeout(() => {
      this._location.go(
        this.translateRouterLink.transform('/booking?' + queryString)[0]
      );
    });
    this.router.navigateByUrl(
      this.translateRouterLink.transform('/booking?' + queryString)[0]
    );
  }
}
