<div class="custom-container">
  <div>
    <mat-divider
      style="height: 100%; margin-right: 50px"
      [vertical]="true"></mat-divider>
  </div>
  <div>
    <ng-container *ngIf="code === 404">
      <h2>
        <span>404.</span>&nbsp; {{ 'SHARED.ERROR-HANDLER.404' | translate }}
      </h2>
      <div>
        {{ 'SHARED.ERROR-HANDLER.TXT_404-MESSAGE' | translate }}
      </div>
    </ng-container>
    <ng-container *ngIf="code === 400">
      <h2>
        <span>400.</span>&nbsp; {{ 'SHARED.ERROR-HANDLER.400' | translate }}
      </h2>
      <div>
        {{ 'SHARED.ERROR-HANDLER.TXT_400-MESSAGE' | translate }}
      </div>
    </ng-container>
    <ng-container *ngIf="code === 500 || !code">
      <h2>
        <span>500.</span>&nbsp; {{ 'SHARED.ERROR-HANDLER.500' | translate }}
      </h2>
      <div>
        {{ 'SHARED.ERROR-HANDLER.TXT_500-MESSAGE' | translate }}
      </div>
    </ng-container>
  </div>
</div>
