import { Pipe, PipeTransform } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

/*
 * Append a locale code to the routerlink
 * Usage:
 *   value | translateRouterLink
 * Example:
 *   {{ '/home' | translateRouterLink }}
 *   formats to: '/en/home'
 */
@Pipe({
  name: 'translateRouterLink',
  // must be set to false, for pipe to reflect new value based on language change
  pure: false,
})
export class TranslateRouterLinkPipe implements PipeTransform {
  routerLink: any;

  constructor(public localizeService: LocalizeRouterService) {
    this.localizeService.routerEvents.subscribe(lang => {
      this.transform(this.routerLink);
    });
  }

  transform(routerLink: string | any[]) {
    this.routerLink = routerLink;

    return this.translateRouterLink(routerLink);
  }

  translateRouterLink(routerLink: any, keepString = false) {
    if (!routerLink) {
      return;
    }
    let translatedRouterLink: any;

    if (typeof routerLink === 'string') {
      routerLink = routerLink.replace('/en/', '/');
      routerLink = routerLink.replace('/fr/', '/');
      routerLink = routerLink.replace('/es/', '/');
      translatedRouterLink = [this.localizeService.translateRoute(routerLink)];
    } else if (routerLink.length) {
      translatedRouterLink = routerLink.slice();
      translatedRouterLink[0] = this.localizeService.translateRoute(
        routerLink[0]
      );
    }

    return translatedRouterLink;
  }
}
