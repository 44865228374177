<div class="top-nav-wrapper">
  <div class="top-nav custom-container">
    <div class="top-nav__language">
      <button mat-button [matMenuTriggerFor]="menu">
        <span class="visually-hidden"
          >Language selection. Current language selected
        </span>
        {{ currentLang }}
      </button>
      <mat-menu class="mat-menu-panel__language" #menu="matMenu">
        <ng-container *ngFor="let lang of languages">
          <button mat-menu-item (click)="selectLanguage(lang.route)">
            {{ lang.label }}
          </button>
        </ng-container>
      </mat-menu>
    </div>
    <div *ngIf="isTimerRunning" class="top-nav__timer">
      {{ 'HEADER.TOP-NAV.LBL_TIMER' | translate }} &nbsp;
      <cd-timer
        #basicTimer
        format="ms"
        [countdown]="true"
        [startTime]="envService.pageRefreshAfterAmountInSec"></cd-timer>
    </div>
  </div>
</div>
