import { ErrorHandlerComponent } from './shared/components/error-handler/error-handler.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SelectivePreloadingStrategyService } from './selective-preloading-strategy.service';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./features/cargowise/cargowise.module').then(
        m => m.CargowiseModule
      ),
  },
  {
    path: 'cargowise-booking',
    loadChildren: () =>
      import('./features/cargowise/cargowise.module').then(
        m => m.CargowiseModule
      ),
  },
  {
    path: 'booking',
    loadChildren: () =>
      import('./features/cargowise/cargowise.module').then(
        m => m.CargowiseModule
      ),
  },
  {
    path: 'footer',
    loadChildren: () =>
      import('./features/footer/footer.module').then(m => m.FooterModule),
  },
  {
    path: 'header',
    loadChildren: () =>
      import('./features/header/header.module').then(m => m.HeaderModule),
  },
  {
    path: 'error',
    data: { titleRef: 'SHARED.PAGE-TITLES.ERROR', code: 500 },
    component: ErrorHandlerComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: true, // <-- debugging purposes only
      preloadingStrategy: SelectivePreloadingStrategyService,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
