import { NgModule } from '@angular/core';
import { HeaderRoutingModule } from './header-routing.module';
import { HeaderComponent } from './header.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { SharedModule } from 'src/app/shared';
import { CdTimerModule } from 'angular-cd-timer';

@NgModule({
  declarations: [HeaderComponent, TopNavComponent],
  imports: [SharedModule, HeaderRoutingModule, CdTimerModule],
  exports: [HeaderComponent, TopNavComponent],
})
export class HeaderModule {}
